.modal {
    backdrop-filter: blur(8px);
    position: absolute;
    /* background: black;
    opacity: 80%; */
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.modalContent {
    /* background: rgb(122, 122, 122); */
    color: white;
    width: 30%;
    min-width: 300px;
    max-width: 500px;
    height: 22%;
    min-height: 150px;
    max-height: 300px;
    z-index: 13;
    opacity: 100%;
    border-radius: .5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 2rem;
    font-family: 'Times New Roman', Times, serif
}

.pwInput {
    background: rgb(59, 59, 59);
    opacity: 40%;
    font-size: 1.5rem;
    border-radius: .7rem;
    padding: .5rem;
    border-style: none;
    max-width: 70%;
    margin: 1rem;
    backdrop-filter: invert 20%;
}

.pwInput:hover {
   opacity: 80%;
}

.button {
    color: rgb(99, 99, 99);
    border-radius: .3rem;
    cursor: pointer;
}

.button:hover {
    color: rgb(71, 66, 33);
    cursor: pointer;
}

.submit {
    color: rgb(99, 99, 99);
    background: none;
    border-style: none;
    font-size: 2rem;
    font-family: 'Times New Roman', Times, serif
}

.submit:hover {
    color: rgb(71, 66, 33);
    cursor: pointer;
}

.highlighter {
    animation: fadeoutBg 3s; /***Transition delay 3s fadeout is class***/
    -moz-animation: fadeoutBg 3s; /* Firefox */
    -webkit-animation: fadeoutBg 3s; /* Safari and Chrome */
    -o-animation: fadeoutBg 3s; /* Opera */
}

@keyframes fadeoutBg {
    from {color: red; } /** from color **/
    to { color: rgb(0, 0, 0); } /** to color **/
}

@-moz-keyframes fadeoutBg { /* Firefox */
    from {color: red; }
    to {color: rgb(0, 0, 0); }
}

@-webkit-keyframes fadeoutBg { /* Safari and Chrome */
    from {color: red; }
    to {color: rgb(0, 0, 0); }
}

@-o-keyframes fadeoutBg { /* Opera */
    from {color: red; }
    to {color: rgb(0, 0, 0); }
}