.full {
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    padding: 3rem;
}

.element:hover {
    fill: #640202;
}

.button:hover {
    fill: rgb(71, 66, 33);
}

.element {
    padding: 10px;
    background-clip:content-box; 
    stroke-width: 10px;
    stroke: white;
    stroke-opacity: 0;
    z-index: 1;
    transition: all .5s ease-out;
}

.circuit {
    padding: 10px;
    background-clip:content-box; 
    stroke-width: 3px;
    stroke: white;
    stroke-opacity: 0;
    transition: .5s ease-out;
}

.circuit:hover {
    stroke: rgb(255, 166, 0);
    stroke-opacity: 1;
}

.laser {
    stroke-width: 20px;
    stroke: white;
    stroke-opacity: 0;
    transition: all .5s ease-in-out;
}

.laser:hover {
    stroke: red;
    stroke-width: 2px;
    stroke-opacity: 1;
}

.square1 {
    transition: all .5s ease-out;
    stroke-width: 10px;
    stroke: white;
    stroke-opacity: 0;
}
.square1:hover {
    stroke: black;
    stroke: 3px;
    fill: black;
}

.background {
    position: absolute;
    z-index: -1;
}